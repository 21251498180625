<template>
  <tr>
    <td>
      <span class="d-block font-size-lg">{{ local_row.titlename }}</span>
    </td>
    <td>
      <b-form-input
        v-model="local_row.num_female"
        type="text"
      ></b-form-input>
    </td>
    <td>
      <b-form-input
        v-model="local_row.num_male"
        type="text"
      ></b-form-input>
    </td>
    <td>
      <b-form-input
        v-model="local_row.num_other"
        type="text"
      ></b-form-input>
    </td>
    <td>
      <b-form-input
        v-model="local_row.num_missing_info"
        type="text"
      ></b-form-input>
    </td>
    <td>
      <b-form-input
        v-model="local_row.num_total"
        type="text"
      ></b-form-input>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'mucf-table-row',
  props: ['row','keyname'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      local_row: {},
    };
  },
  mounted() {
    if (this.row) {
      this.local_row = { ...this.row };
    }
  },
  watch: {
    row: {
      deep: true,
      handler(val) {
        this.local_row = { ...val };
      }
    },
  },
  methods: {

  }
};
</script>
